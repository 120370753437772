import React, { useContext, useEffect, useState, useRef } from "react";
import { Layout } from "../Layouts/Layout";
import { Colors } from "../Commons/Colors/Colors";
import { useNavigate } from "react-router-dom";
import { Paragraph } from "../Commons/Paragraph";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import profileIcon from "../../assets/imgs/profile-default.svg";
import UserProfileCard from "./UserProfileCard/UserProfileCard";
import { Button } from "../ui/button";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { convertDateFormat } from "../../lib/utils";
import { isMobile } from "react-device-detect";
import DeleteAccountModal from "./DeleteAccountModal";

interface profile {
  id?: string | undefined | null;
  user_email?: string | undefined | null;
  phone?: string | undefined | null;
  user_dob?: string | undefined | null;
  user_name?: string | undefined | null;
  avatar_url?: string | undefined | null;
}

const UserProfile = () => {
  const navigate = useNavigate();
  const {
    signout,
    uploadAvatar,
    getUserProfile,
    getUserData,
    deactivateAccount,
  } = useSupabaseAuth();
  const [phone, setPhone] = useState<string | undefined | null>();
  const [email, setEmail] = useState<string | undefined | null>();
  const [name, setName] = useState<string | undefined | null>();
  const [birthday, setBirthDay] = useState<string | undefined | null>();
  const [userId, setUserId] = useState<string | undefined>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [profile, setProfile] = useState<any>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [avatar, setAvatar] = useState<string | undefined | null>(null);
  const [delAccountModal, setDelAccountModal] = useState(false)

  async function signOutHandler() {
    try {
      await signout();
      if (isMobile) {
        window.location.replace("/on-boarding");
      } else {
        window.location.replace("/welcome");
      }
    } catch (error) {
      console.log("error on sign out", error);
    }
  }

  const handlerDeactivateAccount = async () => {
    try {
      await deactivateAccount(userId as string);
      if (isMobile) {
        window.location.replace("/on-boarding");
      } else {
        window.location.replace("/welcome");
      }
    } catch (error) {
      console.log("error on Delete Account", error);
    }
  };

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
      setUserId(response?.data?.user?.id);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchProfile = async () => {
    if (
      currentUser &&
      (currentUser?.app_metadata?.provider === "phone" ||
        currentUser?.app_metadata?.provider === "apple")
    ) {
      const userProfile: profile | null | undefined = await getUserProfile(
        currentUser?.id,
      );

      setEmail(userProfile?.user_email);
      setName(userProfile?.user_name);
      setBirthDay(userProfile?.user_dob);
      setProfile(userProfile);
      setAvatar(userProfile?.avatar_url);
      setPhone(currentUser?.phone);
    } else {
      const identity_data = currentUser?.identities?.[0]?.identity_data ?? null;
      setEmail(identity_data?.email);
      setName(identity_data?.full_name);
      setAvatar(identity_data?.avatar_url);
      setPhone(currentUser?.phone);
    }
  };
  console.log("currentUser **", currentUser);

  useEffect(() => {
    fetchProfile();
  }, [currentUser]);

  const handleFileChange = async (event: any) => {
    fileInputRef.current?.click();
    if (event.target.files) {
      const file = event.target.files[0];
      if (file && userId) {
        await uploadAvatar(file, userId);
        fetchProfile();
      }
    }
  };

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
          style={{
            background: Colors.backgroundSecundary,
          }}
          onClick={() => navigate("/home")}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <Paragraph
          text="Your Profile"
          props={{ fontSize: "28px", fontWeight: 600, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>

      <div className="flex flex-col items-center mt-5">
        <div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            className="hidden"
          />
        </div>
        <button
          className="w-[85px] md:w-[90px] xl:w-[100px] 2xl:w-[110px] h-[85px] md:h-[90px] xl:h-[100px] 2xl:h-[110px] border-[4px] rounded-full flex justify-center items-center mb-3"
          onClick={handleFileChange}
          style={{ borderColor: Colors.backgroundPrimary }}
        >
          <img
            src={avatar ? avatar : profileIcon}
            alt="Profile"
            className={
              avatar
              ? "w-full h-full rounded-full object-cover"
              :"w-10 object-cover cursor-pointer"
            }
          />
        </button>
        <Paragraph
          text="Change Avatar"
          props={{ fontSize: "15px", fontWeight: 600 }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
      </div>
      <div className=" mt-7">
        <Paragraph
          text="Personal Information"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
            lineHeight: "14px",
            marginBottom: "16px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <UserProfileCard
          phoneNumber={phone ? phone : ""}
          email={email ? email : "****@example.com"}
          name={name ? name : "****Mastroianni"}
          birthday={birthday ? "" + convertDateFormat(birthday) : "MM/DD/YYYY"}
        />
      </div>
      <div className="flex flex-col items-center mt-2">
        <Button
          variant="destructive"
          size="default"
          onClick={signOutHandler}
          className="bg-transparent hover:bg-transparent text-[15px] font-semibold"
        >
          {" "}
          Log Out{" "}
        </Button>
      </div>
      <div className="flex flex-col items-center mt-2">
        <Button
          variant="destructive"
          size="default"
          style={{ color: Colors.textRed }}
          onClick={()=>setDelAccountModal(true)}
          className="bg-transparent hover:bg-transparent text-[15px] font-semibold"
        >
          {" "}
          Delete Account{" "}
        </Button>
      </div>
      <DeleteAccountModal isVisible={delAccountModal} onClose={()=>setDelAccountModal(false)} handleDel={handlerDeactivateAccount} />
    </Layout>
  );
};

export default UserProfile;
